/* eslint-disable import/prefer-default-export */
import React from 'react';
import ContentfulRichText from 'components/RichText/ContentfulRichText';
import { GridHeadingContainer } from './styles';

export const GridHeading = ({ title, subtitle }) => {
  return (
    <GridHeadingContainer>
      <div className="careers-grid--heading">
        {title && <ContentfulRichText richText={title} />}
      </div>
      <div className="careers-grid--subhead min-md">
        {subtitle && <ContentfulRichText richText={subtitle} />}
      </div>
    </GridHeadingContainer>
  );
};
