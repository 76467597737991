import React from 'react';
import { GridHeading } from './Heading';
import { GridContent } from './GridContent';
import { ListContent } from './ListContent';
import { getHeading, getGridContent, getListContent } from './GridContent/util';
import { CareersGridContainer } from './styles';

const CareersGridSection = ({ entries }) => {
  const heading = getHeading(entries);

  const gridContent = getGridContent(entries);

  const mobileContent = getListContent(entries);

  return (
    <CareersGridContainer>
      <div className="careers-grid--wrapper">
        <GridHeading title={heading.title} subtitle={heading.subtitle} />
        <GridContent content={gridContent} />
      </div>
      <ListContent content={mobileContent} />
    </CareersGridContainer>
  );
};

export default CareersGridSection;
