import React, { useEffect, useRef } from 'react';
import ContentfulRichText from 'components/RichText/ContentfulRichText';
import styled, { css } from 'styled-components';

const ListTile = ({ title, description, ...colors }) => {
  const containerRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate');
          observer.unobserve(entry.target);
        }
      },
      { rootMargin: '0px 0px -50% 0px' },
    );

    observer.observe(containerRef.current);
  }, [containerRef]);

  const handleDrag = () => {
    const target = containerRef?.current;
    if (target) {
      if (target.classList.contains('animate')) {
        target.classList.remove('animate');
      } else {
        target.classList.add('animate');
      }
    }
  };

  return (
    <ListTileContainer ref={containerRef} {...colors} onClick={handleDrag}>
      <div className="list-tile--title">
        <ContentfulRichText richText={title} />
      </div>
      <div className="list-tile--description">
        <ContentfulRichText richText={description} />
      </div>
    </ListTileContainer>
  );
};

export const ListContent = ({ content }) => {
  return (
    <ListContainer className="max-md">
      {content?.map((x, index) => (
        <ListTile key={index} {...x} />
      ))}
    </ListContainer>
  );
};

const ListContainer = styled.div`
  overflow: hidden;

  & > div:nth-child(even) {
    flex-direction: row-reverse;
    transform: translate(-42%);
    &.animate {
      transform: none;
    }
  }
  & > div:nth-child(odd) {
    &.animate {
      transform: translate(-42%);
    }
  }
`;

const sharedStyles = css`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ListTileContainer = styled.div`
  display: flex;
  width: 175%;
  transition-duration: 700ms;
  transition-delay: 200ms;
  .list-tile--description,
  .list-tile--title {
    ${sharedStyles}
  }
  .list-tile--title {
    padding: 70px 30px;
    background-color: ${({ titleColor }) => titleColor};
  }
  .list-tile--description {
    padding: 38px 30px;
    background-color: ${({ descriptionColor }) => descriptionColor};
  }
`;
