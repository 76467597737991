import styled from 'styled-components';

export const GridContentContainer = styled.div`
  margin-top: 40px;
`;

export const GridTable = styled.table`
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-spacing: 0;
`;

export const GridRow = styled.tr`
  padding: 0;
`;

export const GridCol = styled.td`
  border: 1px solid var(--white);
  display: table-cell;
  padding: 0;

  .careers-grid-item--container {
    min-height: 0;
    width: 100%;
    padding-top: ${({ aspectRatio }) => `${(aspectRatio || 1) * 90.909}%`};
  }
`;

export const GridTileContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  .careers-grid-item--wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  @media (max-width: 1060px) {
    p {
      font-size: inherit;
    }
  }
  @media (max-width: 991px) {
    h4 {
      font-size: inherit;
    }
    p {
      font-size: 10px;
    }
  }

  ${({ $type, image }) =>
    $type === 'image' &&
    `
    &.careers-grid-item--image-type {
      .careers-grid-item--wrapper {
        ${image ? `background-image: url(${image?.file?.url});` : ''}
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  `}

  ${({ $type, $color }) =>
    $type === 'hover' &&
    `
    &.careers-grid-item--hover-type {
      .careers-grid-item--wrapper {
        background: var(--darkest);
        &.hover {
          background: ${$color};
          .careers-grid-item--panel-subtitle {
            visibility: visible;
            opacity: 1;
          }
          .careers-grid-item--hover-image-container {
            .careers-grid-item--hover-image {
              opacity: 1;
              visibility: visible;
            }
          }
        }
        .careers-grid-item--panel-subtitle {
          position: absolute;
          padding: 25px;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          visibility: hidden;
          opacity: 0;
          transition: all 0.3s ease-out;
        }

        .careers-grid-item--hover-image-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 100%;

          .careers-grid-item--hover-image {
            margin: 0 auto;
            visibility: hidden;
            opacity: 0;
            width: 100px;
            transition: all 0.3s ease-out;
          }
        }
      }
    }
  `}

  ${({ $type, $color }) =>
    $type === 'text' &&
    `
    &.careers-grid-item--panel-type {
      .careers-grid-item--wrapper {
        background: ${$color};

        .careers-grid-item--panel-title {
          position: absolute;
          padding: 25px;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          visibility: visible;
          opacity: 1;
          transition: all 0.3s ease-out;
        }
      }
    }
  `}
`;
