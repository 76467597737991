import { getEntry } from 'components/Contentful/ContentfulLayout/utils/entriesHelpers';

export const getHeading = (entries) => {
  return {
    title: getEntry('heading-title', entries),
    subtitle: getEntry('heading-subtitle', entries),
  };
};

export const getGridContent = (entries) => [
  [
    {
      type: 'text',
      title: getEntry('be-you-title', entries),
      color: 'var(--titan-blue-3)',
    },
    {
      type: 'hover',
      hoverTitle: getEntry('be-you-hover-title', entries),
      color: 'var(--titan-blue-3)',
      className: 'pair-1',
    },
    {
      type: 'hover',
      hoverImage: getEntry('you-image', entries),
      color: 'var(--titan-blue-3)',
      className: 'pair-1',
    },
    {
      type: 'text',
      title: getEntry('bring-your-best-title', entries),
      color: 'var(--yellow)',
    },
  ],
  [
    {
      type: 'hover',
      hoverImage: getEntry('paintbrush-image', entries),
      color: 'var(--critical-4)',
      className: 'pair-4',
    },
    {
      type: 'hover',
      hoverTitle: getEntry('keep-it-human-hover-title', entries),
      color: 'var(--skyline-3)',
      className: 'pair-3',
    },
    {
      type: 'text',
      title: getEntry('keep-it-human-title', entries),
      color: 'var(--skyline-3)',
    },
    {
      type: 'hover',
      hoverTitle: getEntry('bring-your-best-hover-title', entries),
      color: 'var(--yellow)',
      className: 'pair-2',
    },
  ],
  [
    {
      type: 'hover',
      hoverTitle: getEntry('make-bold-moves-hover-title', entries),
      color: 'var(--critical-4)',
      className: 'pair-4',
    },
    {
      type: 'text',
      title: getEntry('make-bold-moves-title', entries),
      color: 'var(--critical-4)',
    },
    {
      type: 'hover',
      hoverImage: getEntry('earth-image', entries),
      color: 'var(--skyline-3)',
      className: 'pair-3',
    },
    {
      type: 'hover',
      hoverImage: getEntry('basketball-image', entries),
      color: 'var(--yellow)',
      className: 'pair-2',
    },
  ],
  [
    {
      type: 'text',
      title: getEntry('work-your-way-title', entries),
      color: 'var(--green)',
    },
    {
      type: 'hover',
      hoverTitle: getEntry('work-your-way-hover-title', entries),
      color: 'var(--green)',
      className: 'pair-5',
    },
    {
      type: 'hover',
      hoverImage: getEntry('desk-accessories-image', entries),
      color: 'var(--green)',
      className: 'pair-5',
    },
    {
      type: 'image',
      image: getEntry('playlist-image', entries),
      cta: getEntry('playlist-cta', entries),
    },
  ],
];

export const getListContent = (entries) => [
  {
    title: getEntry('be-you-title', entries),
    titleColor: 'var(--titan-blue-3)',
    description: getEntry('be-you-hover-title', entries),
    descriptionColor: '#14438E',
  },
  {
    title: getEntry('make-bold-moves-title', entries),
    titleColor: 'var(--critical-4)',
    description: getEntry('make-bold-moves-hover-title', entries),
    descriptionColor: 'var(--critical-5)',
  },
  {
    title: getEntry('bring-your-best-title', entries),
    titleColor: 'var(--yellow)',
    description: getEntry('bring-your-best-hover-title', entries),
    descriptionColor: '#DE9500',
  },
  {
    title: getEntry('keep-it-human-title', entries),
    titleColor: 'var(--skyline-3)',
    description: getEntry('keep-it-human-hover-title', entries),
    descriptionColor: 'var(--skyline-5)',
  },
  {
    title: getEntry('work-your-way-title', entries),
    titleColor: 'var(--green)',
    description: getEntry('work-your-way-hover-title', entries),
    descriptionColor: '#007A4D',
  },
];
