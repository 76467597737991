/* eslint-disable import/prefer-default-export */
import React from 'react';
import ContentfulRichText from 'components/RichText/ContentfulRichText';
import HyperLink from 'components/Core/HyperLink';
import { GridTileContainer } from './styles';
import WebsiteImage from 'components/WebsiteImage';

export const GridTile = ({ content }) => {
  const handleMouseOver = (className) => {
    const tiles = document.querySelectorAll(`.${className}`);
    tiles.forEach((x) => x.classList.add('hover'));
  };

  const handleMouseOut = (className) => {
    const tiles = document.querySelectorAll(`.${className}`);
    tiles.forEach((x) => x.classList.remove('hover'));
  };

  if (!content) {
    return <div />;
  }

  const { type } = content;

  if (type === 'image') {
    const { image, cta } = content;

    return (
      <GridTileContainer
        className="careers-grid-item--container careers-grid-item--image-type"
        image={image?.image}
        $type={type}
      >
        {cta ? (
          <HyperLink target="_blank" href={cta?.url} data-testid="grid-tile-link">
            <div className="careers-grid-item--wrapper" data-testid="grid-tile-wrapper" />
          </HyperLink>
        ) : (
          <div className="careers-grid-item--wrapper" data-testid="grid-tile-wrapper" />
        )}
      </GridTileContainer>
    );
  }

  if (type === 'text') {
    const { title, color } = content;

    return (
      <GridTileContainer
        className="careers-grid-item--container careers-grid-item--panel-type"
        $type={type}
        $color={color}
      >
        <div className="careers-grid-item--wrapper" data-testid="grid-tile-wrapper">
          {title && (
            <div className="careers-grid-item--panel-title">
              <ContentfulRichText richText={title} />
            </div>
          )}
        </div>
      </GridTileContainer>
    );
  }

  if (type === 'hover') {
    const { hoverTitle, hoverImage, color, className } = content;
    return (
      <GridTileContainer
        className="careers-grid-item--container careers-grid-item--hover-type"
        $type={type}
        $color={color}
        onMouseOver={() => handleMouseOver(className)}
        onMouseOut={() => handleMouseOut(className)}
      >
        <div className={`careers-grid-item--wrapper ${className}`} data-testid="grid-tile-wrapper">
          {hoverTitle && (
            <div className="careers-grid-item--panel-subtitle">
              <ContentfulRichText richText={hoverTitle} />
            </div>
          )}
          {hoverImage && (
            <div className="careers-grid-item--hover-image-container">
              <WebsiteImage className="careers-grid-item--hover-image" image={hoverImage} />
            </div>
          )}
        </div>
      </GridTileContainer>
    );
  }

  return <div />;
};
