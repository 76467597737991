/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const CareersGridContainer = styled.div`
  .careers-grid--wrapper {
    max-width: 1244px;
    margin: 0 auto;
    padding: 37px 28px;
  }
`;
