/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const GridHeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  h2 {
    margin-bottom: 0;
    @media (max-width: 575px) {
      padding: 0px 50px;
    }
  }

  .careers-grid--subhead {
    max-width: 600px;
    margin: 10px 0 0;
    @media (max-width: 575px) {
      margin: 10px 40px 0px;
    }
  }
`;
