/* eslint-disable import/prefer-default-export */
import React from 'react';
import { GridTile } from './Tile';
import { GridContentContainer, GridTable, GridRow, GridCol } from './styles';

export const GridContent = ({ content }) => (
  <GridContentContainer className="careers-grid--grid-container min-md">
    <GridTable>
      {content?.map((row, rowIndex) => (
        <GridRow key={rowIndex}>
          {row?.length > 0 &&
            row.map((col, colIndex) => {
              return (
                <GridCol {...col.colStyle} key={colIndex}>
                  <GridTile content={col} />
                </GridCol>
              );
            })}
        </GridRow>
      ))}
    </GridTable>
  </GridContentContainer>
);
